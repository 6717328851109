<script setup lang="ts">
import { TemporaryProps } from "./Temporary.props"

const dynamicId = computed(() => `link-${Math.random()}`)

const props = defineProps<TemporaryProps>()

const { title, promoId, promotions } = toRefs(props)
const promotion = promotions.value.filter(
  (p) => p.promotion_id === promoId.value
)
const triggerLink = () => navigateTo(props.path)

const isHovered = ref(false)
</script>

<template>
  <GASelectPromotion
    :promoId="promoId ?? ''"
    :promotion="promotion"
    :title="title ?? ''"
    :promoLocation="promoLocation ?? ''"
    @click="triggerLink"
    class="h-full"
  >
    <div class="inline-block h-full w-[242px] md:w-full">
      <div class="relative overflow-hidden rounded">
        <UtilsWrapperImage
          v-if="image"
          sizes="200px md:200px lg:250px xl:300px"
          class="wrapper-image-full-container-centered"
          v-bind="image"
        />
      </div>

      <AppLink :id="dynamicId" :to="path">
        <p
          v-if="title"
          class="beaver-bold md:pig-bold mt-1"
          :class="{ underline: isHovered }"
        >
          {{ title }}
        </p>
      </AppLink>

      <UtilsMarkdown
        v-if="description"
        class="
          mouse
          md:beaver
          markdown__link-hover-underline
          mt-2
          text-black-80
        "
        :class="{ underline: isHovered }"
        :content="description"
      />
    </div>
  </GASelectPromotion>
</template>
