<script setup lang="ts">
import { PromoProps } from "./Promo.props"
import { smOptions, mdOptions } from "./Promo.carouselOptions"
import { sm } from "~/utils/breakpoints"

const props = defineProps<PromoProps>()

const carouselOptions = computed(() => (sm.value ? smOptions : mdOptions))

const gaItem = props.cards.map((item, index) => {
  return {
    creative_name: props.title ?? "",
    creative_slot: "featured_slide_" + (index + 1),
    promotion_id: item.promoId ?? "",
    promotion_name: item.title ?? ""
  }
})
</script>

<template>
  <section
    class="
      padded
      carousel-container-overflowed
      bg-gradient-to-r
      from-critical-main/30
      to-white
      py-6
      md:py-8
    "
  >
    <div class="mb-4 flex w-full items-center justify-between md:mb-6">
      <section class="flex flex-col gap-1">
        <p v-if="caption" class="beaver md:pig-medium uppercase">
          {{ caption }}
        </p>
        <p v-if="title" class="elephant-bold md:mammoth-bold">
          {{ title }}
        </p>
      </section>
      <UtilsButton
        v-if="button"
        v-bind="button"
        theme="outlined-green"
        class="hidden md:block"
      />
    </div>

    <GAViewPromotion :items="gaItem" id="view-promotion">
      <AppCarousel
        class="carousel-overflowed select-none"
        :swiper-props="carouselOptions"
        :items="cards"
        :theme="carouselOptions?.theme"
        :key="sm ? 1 : 0"
      >
        <template #default="{ item }">
          <CardPromoTemporary
            class="swiper-tile"
            v-bind="item"
            promo-location="top"
            :promotions="gaItem"
          />
        </template>
      </AppCarousel>
    </GAViewPromotion>
    <div class="mt-4">
      <UtilsButton
        v-if="button"
        v-bind="button"
        theme="outlined-green"
        class="shown w-full md:hidden"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>

:deep(.swiper-slide:first-child > div) {
  margin-left: 0;
}

:deep(.swiper-slide:last-child > div) {
  margin-right: 0;
}

:deep(.swiper.swiper-color-navigation-dark-rails-promo) {
  padding-bottom: 35px;
  @screen md {
    padding-bottom: 100px;
  }
}

.swiper-tile {
  margin: 0 8px;
}

@screen md {
  .swiper-tile {
    margin: 0 12px;
    width: calc(((100vw - 142px) / 4) - 24px);
  }
}

@screen lg {
  .swiper-tile {
    width: calc(((100vw - 456px) / 4) - 24px);
  }
}

</style>
