<script setup lang="ts">
import type { ComponentPromotionsProps } from "./ComponentPromotions.props"
const props = defineProps<ComponentPromotionsProps>()
</script>

<template>
  <div class="cms-component-promotions">
    <RailsPromo v-bind="props" :button="cta" v-if="props" />
  </div>
</template>
